/*
	Only applicable for:
	buttons, labels, boxes, feature columns, feature icons,
	progress bars, text fields and textareas.
*/

/* Padding */
/* Border/Bkg padding */
[class*="border-"].padded,
[class*="bkg-"].padded{
  padding: 1rem 2rem;
}

/* Border Color Classes */
[class*="border-"]{
  border: 1px solid #eee;
}
[class*="border-"]:not([class*="bkg-"]),
[class*="border-"][class*="bkg-hover-"],
[class*="border-hover-"]:hover{
  background: none;
  background: rgba(0,0,0,0);
}
/* Styles */
[class*="border-"].solid{
  border-style: solid;
  border-width: 1px;
}
[class*="border-"].dashed{
  border-style: dashed;
  border-width: 1px;
}
[class*="border-"].dotted{
  border-style: dotted;
  border-width: 1px;
}
[class*="border-"].thick,
[class*="border-"].thick *{
  border-width: 0.2rem;
}

/* Background Color Classes */
[class*="bkg-hover-"]:hover *,
[class*="bkg-hover-"]:visited *{
  color: inherit;
}

.bkg-white{background: $white; border-color: $white;}
.bkg-hover-white:hover{background: $white; border-color: $white;}
.bkg-ghost-white{background: $ghost-white; border-color: $ghost-white;}
.bkg-hover-ghost-white:hover{background: $ghost-white; border-color: $ghost-white;}
.bkg-ghost-white-gradient-to-top {background: linear-gradient(to top, #f9fcfd, #fff);}
.bkg-ghost-white-gradient-to-bottom {background: linear-gradient(to bottom, #f9fcfd, #fff);}

.bkg-blue{background: $blue; border-color: $blue;}
.bkg-hover-blue:hover{background: $blue; border-color: $blue;}
.bkg-light-blue{background: $light-blue; border-color: $light-blue;}
.bkg-hover-light-blue:hover{background: $light-blue; border-color: $light-blue;}
.bkg-dark-blue{background: $dark-blue; border-color: $dark-blue;}
.bkg-hover-dark-blue:hover{background: $dark-blue; border-color: $dark-blue;}

.bkg-orange{background: $orange; border-color: $orange;}
.bkg-hover-orange:hover{background: $orange; border-color: $orange;}
.bkg-red{background: $red; border-color: $red;}
.bkg-hover-red:hover{background: $red; border-color: $red;}
.bkg-green{background: $green; border-color: $green;}
.bkg-hover-green:hover{background: $green; border-color: $green;}

.bkg-black{background: $black; border-color: $black;}
.bkg-hover-black:hover{background: $black; border-color: $black;}
.bkg-grey{background-color: $grey-80; border-color: $grey-80;}
.bkg-hover-grey:hover{background-color: $grey-80; border-color: $grey-80;}
.bkg-ghost-grey{background-color: $ghost-grey; border-color: $ghost-grey;}
.bkg-hover-ghost-grey:hover{background-color: $ghost-grey; border-color: $ghost-grey;}


/* Social Networks Background Color Classes */
.bkg-facebook,
.bkg-hover-facebook:hover{background-color: #3b5998; border-color: #3b5998;}
.bkg-google,
.bkg-hover-google:hover{background-color: #d34836; border-color: #d34836;}
.bkg-linkedin,
.bkg-hover-linkedin:hover{background-color: #007bb6; border-color: #007bb6;}
.bkg-pinterest,
.bkg-hover-pinterest:hover{background-color: #cb2027; border-color: #cb2027;}
.bkg-twitter,
.bkg-hover-twitter:hover{background-color: #00aced; border-color: #00aced;}



.border-white,
.border-hover-white:hover{border-color: $white;}
.border-ghost-white,
.border-hover-ghost-white:hover{border-color: $ghost-white;}
.border-ghost-grey,
.border-hover-ghost-grey:hover{border-color: $ghost-grey;}
.border-blue,
.border-hover-blue:hover{border-color: $blue;}
.border-light-blue,
.border-hover-light-blue:hover{border-color: $light-blue;}
.border-dark-blue,
.border-hover-dark-blue:hover{border-color: $dark-blue;}
.border-orange,
.border-hover-orange:hover{border-color: $orange;}
.border-blue,
.border-hover-blue:hover{border-color: $blue;}
.border-green,
.border-hover-green:hover{border-color: $green;}
.border-black,
.border-hover-black:hover{border-color: $black;}

/* Font Color Classes */
[class*="color-"] *{
  color: inherit !important;
}

.color-white{color: $white;}
.color-hover-white:hover{color: $white;}
.color-ghost-white{color: $ghost-white;}
.color-hover-ghost-white:hover{color: $ghost-white;}

.color-blue{color: $blue;}
.color-hover-blue:hover{color: $blue;}
.color-light-blue{color: $light-blue;}
.color-hover-light-blue:hover{color: $light-blue;}
.color-dark-blue{color: $dark-blue;}
.color-hover-navy:hover{color: $dark-blue;}

.color-orange{color: $orange;}
.color-hover-orange:hover{color: $orange;}
.color-red{color: $red;}
.color-hover-red:hover{color: $red;}
.color-green{color: $green;}
.color-hover-green:hover{color: $green;}

.color-grey{color: $grey-80;}
.color-hover-grey:hover{color: $grey-80;}
.color-black{color: $black;}
.color-hover-black:hover{color: $black;}


/* Social Networks Font Color Classes */
.color-facebook,
.color-hover-facebook:hover{color: #3b5998;}
.color-google,
.color-hover-google:hover{color: #d34836;}
.color-linkedin,
.color-hover-linkedin:hover{color: #007bb6;}
.color-pinterest,
.color-hover-pinterest:hover{color: #cb2027;}
.color-twitter,
.color-hover-twitter:hover{color: #00aced;}

/* Form element place holder text color*/
[class*="bkg-"]::-webkit-input-placeholder,
[class*="border-"]::-webkit-input-placeholder,
[class*="color-"]::-webkit-input-placeholder{
  color: inherit;
}

[class*="bkg-"]::-moz-placeholder,
[class*="border-"]::-moz-placeholder,
[class*="color-"]::-moz-placeholder{
  color: inherit;
}

[class*="bkg-"]:-ms-input-placeholder,
[class*="border-"]:-ms-input-placeholder,
[class*="color-"]:-ms-input-placeholder{
  color: inherit;
}

/* Special Color Classes */
.highlight{
  background: $ghost-grey;
  border-color: $ghost-grey;
}
.info{
  background: #d9edf7;
  color: #31708f;
  border-color:#d9edf7;
}
.success{
  background: #dff0d8;
  color: #3c763d;
  border-color: #dff0d8;
}
.alert{
  background: #fcf8e3;
  color: #8a6d3b;
  border-color: #fcf8e3;
}
.warning{
  background: #f2dede;
  color: #a9444a;
  border-color: #f2dede;
}