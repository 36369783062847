blockquote{
  margin: 0 0 3rem 0;
  font-size: 1.8rem;
  line-height: 1.5;
}
blockquote p{
  margin-bottom: 0;
  font-size: 1.8rem;
}
blockquote cite,
blockquote .cite{
  margin-top: 2rem;
  font-size:60%;
  display: block;
}

/* Sizes */
blockquote.small,
blockquote.small p {
  font-size: 1.6rem;
}
blockquote.medium,
blockquote.medium p {
  font-size: 1.8rem;
}
blockquote.large,
blockquote.large p {
  font-size: 2.1rem;
}
blockquote.xlarge,
blockquote.xlarge p {
  font-size: 2.4rem;
}

/* Styles */
blockquote.border{
  padding: 0 0 0 2.5rem;
  border-left: 1px solid #eeeeee;
}
blockquote.border.small{
  padding-left: 2rem;
}
blockquote.border.medium {
  padding-left: 2.5rem;
}
blockquote.border.large{
  padding-left: 3rem;
}
blockquote.border.xlarge{
  padding-left: 3.5rem;
}
blockquote.border.small{
  padding-right: 2rem;
}
blockquote.border.medium {
  padding-right: 2.5rem;
}
blockquote.border.large{
  padding-right: 3rem;
}
blockquote.border.xlarge{
  padding-right: 3.5rem;
}
blockquote.icon [class*="icon-"]{
  font-size: 150%;
  text-align: center;
  line-height: 0;
}
blockquote.avatar span,
blockquote.avatar span img{
  width: 5rem;
  height: 5rem;
  border-radius: 7rem;
  display: inline-block;
}
blockquote.avatar.small span,
blockquote.avatar.small span img{
  width: 4rem;
  height: 4rem;
}
blockquote.avatar.medium span,
blockquote.avatar.medium span img{
  width: 5rem;
  height: 5rem;
}
blockquote.avatar.large span,
blockquote.avatar.large span img{
  width: 6rem;
  height: 6rem;
}
blockquote.avatar.xlarge span,
blockquote.avatar.xlarge span img{
  width: 7rem;
  height: 7rem;
}

/* Alignment */
blockquote.pull-left{
  width: 40%;
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
blockquote.pull-right{
  width: 40%;
  float: right;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
blockquote.center{
  margin-left: auto;
  margin-right: auto;
}
blockquote.border.right{
  padding: 0 2.5rem 0 0;
  border-left: none;
  border-right: 1px solid #eeeeee;
}
blockquote.border.center{
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 0 0 0;
  border-left: none;
  border-top: 1px solid #eeeeee;
}