img{
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none;
}

/*Alignment*/
img.pull-left,
.thumbnail.pull-left{
  margin: 0 1.5rem 1rem 0;
}
img.pull-right,
.thumbnail.pull-right{
  margin: 0 0 1.5rem 1rem;
}

/* Thumbnail */
.thumbnail{
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
}
.thumbnail.rounded,
img.rounded{
  border-radius: 50%;
}
.thumbnail > span,
.thumbnail > a{
  width: 100%;
  height:100%;
  float: left;
}
.thumbnail video{
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}
.thumbnail video + img{
  position: absolute;
  top: 0;
  z-index: 0;
}
/* ff fix for flicker on hover */
.thumbnail:hover {
  transform: none !important;
  transition: none !important;
}

/* Captions */
.caption-below{
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-style: italic;
  border: 1px solid #eeeeee;
  float: left;
}
.thumbnail .caption-over-outer{
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: table;
  opacity: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.thumbnail .caption-over-inner{
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.thumbnail .caption-over-inner *:first-child{
  margin-top: 0;
}
.thumbnail .caption-over-inner *:last-child{
  margin-bottom: 0;
}

/* Overlays */
.thumbnail > span,
.thumbnail > a{
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
  border: none;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
.overlay-info{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: $white;
  border: none;
  background: $black;
  background: rgba(0,0,0,0.5);
  text-align: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
a.overlay-info:hover{
  color: $white;
}
.overlay-info > span,
.overlay-info > div{
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-style: normal;
  display: table;
}
.overlay-info > span > span,
.overlay-info > div > div{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

/* Background Images */
.fill-background{
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

/* Horizontal Alignment */
.overlay-info.left > span > span{
  text-align: left;
}
.overlay-info.right > span > span{
  text-align: right;
}

/* Vertical Alignment */
.thumbnail .caption-over-inner.v-align-top,
.overlay-info.v-align-top > span > span,
.overlay-info.v-align-top > div > div{
  vertical-align: top;
}
.thumbnail .caption-over-inner.v-align-bottom,
.overlay-info.v-align-bottom > span > span,
.overlay-info.v-align-bottom > div > div{
  vertical-align: bottom;
}

/* Animation Classes */
.thumbnail .overlay-info{
  width: 100.1%;
  line-height: 1;
  opacity: 0;
}
.thumbnail:hover .overlay-info{
  opacity: 1;
}
.thumbnail .overlay-info{
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.thumbnail:hover .overlay-info,
.thumbnail:hover img{
  -webkit-transform: translate3d(0,0,0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
  transform: translate3d(0,0,0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
}
.overlay-fade-out .overlay-info,
.overlay-fade-img-scale-in .overlay-info,
.overlay-fade-img-scale-out .overlay-info{
  opacity: 1;
}
.overlay-fade-out:hover .overlay-info,
.overlay-fade-img-scale-in:hover .overlay-info,
.overlay-fade-img-scale-out:hover .overlay-info{
  opacity: 0;
}
.overlay-slide-in-top .overlay-info{
  opacity: 0;
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}
.overlay-slide-in-right .overlay-info{
  opacity: 0;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}
.overlay-slide-in-bottom .overlay-info{
  opacity: 0;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}
.overlay-slide-in-left .overlay-info{
  opacity: 0;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}
.overlay-scale-in .overlay-info{
  opacity: 0;
  -webkit-transform: scale3d(0.1,0.1,1);
  transform: scale3d(0.1,0.1,1);
}
.overlay-rotate-in .overlay-info{
  opacity: 0;
  -webkit-transform: rotate3d(0.1, 0.1, 1, -180deg);
  transform: rotate3d(0.1, 0.1, 1, -180deg);
}
.overlay-img-slide-right .overlay-info{
  opacity: 1;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}
.overlay-img-slide-right:hover img{
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}
.overlay-img-slide-left .overlay-info{
  opacity: 1;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}
.overlay-img-slide-left:hover img{
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}
.overlay-img-slide-down .overlay-info{
  opacity: 1;
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}
.overlay-img-slide-down:hover img{
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}
.overlay-img-slide-up .overlay-info{
  opacity: 1;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}
.overlay-img-slide-up:hover img{
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}
.img-scale-in:hover img,
.img-scale-out img,
.overlay-fade-img-scale-out img,
.overlay-fade-img-scale-in:hover img,
.overlay-img-scale-in:hover img{
  opacity: 1;
  -webkit-transform: scale3d(1.2,1.2,1);
  transform: scale3d(1.2,1.2,1);
}
.overlay-img-scale-in .overlay-info{
  -webkit-transform: scale3d(0.4,0.4,1);
  transform: scale3d(0.4,0.4,1);
}