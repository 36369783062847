/* Grid Item*/
[class*="content-grid"] .grid-item{
  margin: 0 0 3rem;
  float: left;
}
[class*="content-grid"] .thumbnail{
  float: none;
}

/* Item Widths */
.content-grid-1 .grid-item{
  width: 100%;
}
.content-grid-2 .grid-item{
  width: 50%;
}
.content-grid-3 .grid-item{
  width: 33.33333%;
}
.content-grid-4 .grid-item{
  width: 25%;
}
.content-grid-5 .grid-item{
  width: 20%;
}
.content-grid-6 .grid-item{
  width: 16.66667%;
}

/* Marginless Grid */
[class*="content-grid-"].no-margins,
.row [class*="content-grid-"].no-margins{
  margin-right: 0 !important;
  margin-left: 0 !important;
}
[class*="content-grid-"].no-margins .grid-item{
  padding: 0 !important;
  margin: 0 !important;
}

/* Text Column */
.text-column{
  margin-bottom: 3rem;
}
.text-column > *:last-child{
  margin-bottom: 0;
}