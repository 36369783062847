.feature-column-group .feature-column{
  padding: 7rem;
  margin-bottom: 0;
}
.feature-column{
  margin-bottom: 3rem;
}
.feature-column > *:last-child{
  margin-bottom: 0;
}
.feature-column .feature-icon{
  font-size: 3rem;
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
}
.feature-column.left .feature-icon{
  float: left;
}
.feature-text.left{
  padding-left: 5rem;
}
.feature-column.right .feature-text{
  padding-right: 5rem;
  padding-left: 0;
}
.feature-text *:first-child{
  margin-top: 0;
}

/* Sizes and Positions */
.feature-column.small .feature-icon{
  font-size: 2rem;
}
.feature-column.left.small .feature-text{
  padding-left: 4rem;
}
.feature-column.left.small .icon-boxed,
.feature-column.left.small .icon-circled{
  width: 5.8rem;
  height: 5.8rem;
  line-height: 5.8rem;
}
.feature-column.left.small .icon-boxed + .feature-text,
.feature-column.left.small .icon-circled + .feature-text{
  padding-left: 9rem;
}
.feature-column.right.small .feature-text{
  padding-right: 4rem;
  padding-left: 0;
}
.feature-column.right.small .icon-boxed + .feature-text,
.feature-column.right.small .icon-circled + .feature-text{
  padding-right: 9rem;
  padding-left: 0;
}
.feature-colum.mediumn .feature-icon{
  font-size: 3rem;
}
.feature-column.left.medium .feature-text{
  padding-left: 5rem;
}
.feature-column.medium .icon-boxed,
.feature-column.medium .icon-circled{
  width: 6.6rem;
  height: 6.6rem;
  line-height: 6.6rem;
}
.feature-column.left .icon-boxed + .feature-text,
.feature-column.left .icon-circled + .feature-text,
.feature-column.left.medium .icon-boxed + .feature-text,
.feature-column.left.medium .icon-circled + .feature-text{
  padding-left: 9.8rem;
}
.feature-column.right .icon-boxed + .feature-text,
.feature-column.right .icon-circled + .feature-text,
.feature-column.right.medium .icon-boxed + .feature-text,
.feature-column.right.medium .icon-circled + .feature-text{
  padding-right: 9.8rem;
  padding-left: 0;
}
.feature-column.large .feature-icon{
  width: 7.4rem;
  height: 7.4rem;
  font-size: 4rem;
  line-height: 7.4rem;
}
.feature-column.left.large .feature-text{
  padding-left: 6rem;
}
.feature-column.large .icon-boxed,
.feature-column.large .icon-circled{
  width: 7.4rem;
  height: 7.4rem;
  line-height: 7.4rem;
}
.feature-column.left.large .icon-boxed + .feature-text,
.feature-column.left.large .icon-circled + .feature-text{
  padding-left: 10.6rem;
}
.feature-column.right.large .icon-boxed + .feature-text,
.feature-column.right.large .icon-circled + .feature-text{
  padding-right: 10.6rem;
  padding-left: 0;
}
.feature-column.xlarge .feature-icon{
  font-size: 5rem;
}
.feature-column.left.xlarge .feature-text{
  padding-left: 7rem;
}
.feature-column.xlarge .icon-boxed,
.feature-column.xlarge .icon-circled{
  width: 9rem;
  height: 9rem;
  line-height: 9rem;
}
.feature-column.left.xlarge .icon-boxed + .feature-text,
.feature-column.left.xlarge .icon-circled + .feature-text{
  padding-left: 12.2rem;
}
.feature-column.right.xlarge .icon-boxed + .feature-text,
.feature-column.right.xlarge .icon-circled + .feature-text{
  padding-right: 12.2rem;
  padding-left: 0;
}
.feature-column.right .feature-icon{
  float: right;
  margin: 0 auto 2rem auto;
}
.feature-column.center .feature-icon{
  display: inline-block;
  float: none;
  margin: 0 auto 2rem auto;
}
.feature-column.center .feature-icon + .feature-text{
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 768px) {
  .feature-column.center-on-mobile .feature-icon{
    display: inline-block;
    float: none;
    margin: 0 auto 2rem auto;
  }
  .feature-column.center-on-mobile .feature-icon + .feature-text{
    padding-left: 0;
    padding-right: 0;
  }
}