.divider,
hr {
  margin: 3rem 0;
  border-top: 1px solid #eeeeee;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  height: 0;
  clear:both;
}
.divider.dashed,
hr.dashed {
  border-style: dashed;
}
.divider.dotted,
hr.dotted {
  border-style: dotted;
}
.divider.thick,
hr.thick{
  border-top-width: 0.2rem;
}
.divider[class*="border-"],
hr[class*="border-"],
.divider[class*="border-"].dashed,
.divider[class*="border-"].dotted,
hr[class*="border-"].dashed,
hr[class*="border-"].dotted{
  border-right-style: none !important;
  border-bottom-style: none !important;
  border-left-style: none !important;
}