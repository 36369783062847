.reveal-side-navigation > div:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(0,0,0,0);
  content: '';
  opacity: 0;
  z-index: 110;
  transition: opacity 300ms, width 0ms, height 0ms;
}
.inactive.reveal-side-navigation > div:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 300ms;
}

/* Associated Wrapper Animation */
.element-reveal-left{
  transform: translate3d(35rem,0,0);
}
.element-reveal-right{
  transform: translate3d(-35rem,0,0);
}

/* Side Navigation Wrapper */
.side-navigation-wrapper,
.side-navigation-inner{
  width: 35rem;
}
.side-navigation-wrapper{
  height: 100%;
  padding: 0;
  background: $black;
  overflow-y: scroll;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: auto;
  transform: translate3d(-100%,0,0);
  -webkit-backface-visibility: hidden;
}

.side-navigation-wrapper.active{
  visibility: visible;
}
.side-navigation-wrapper.enter-right{
  text-align: left;
  right: 0;
  left: auto;
  transform: translate3d(100%,0,0);
}
.side-navigation-wrapper.no-scrollbar{
  overflow: hidden;
}
.no-scrollbar .side-navigation-scroll-pane{
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 0;
}
.no-scrollbar .side-navigation-scroll-pane::-webkit-scrollbar{
  display: none;
}
.side-navigation-inner{
  min-height: 100%;
  position: relative;
}

/* Side Navigation Animation Classes */
/* no transition */
.side-navigation-wrapper.no-transition-reset{
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
/* slide in */
.side-navigation-wrapper.slide-in-reset{
  z-index: 104;
}
.side-navigation-wrapper.slide-in{
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.element-reveal-left.slide-in,
.element-reveal-right.slide-in{
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* push in */
.side-navigation-wrapper.push-in-reset{
  -webkit-transform: translate3d(-60%,0,0);
  transform: translate3d(-60%,0,0);
}
.side-navigation-wrapper.enter-right.push-in-reset{
  -webkit-transform: translate3d(60%,0,0);
  transform: translate3d(60%,0,0);
}
.side-navigation-wrapper.push-in,
.side-navigation-wrapper.enter-right.push-in{
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* reveal */
.side-navigation-wrapper.reveal-reset,
.side-navigation-wrapper.enter-right.reveal-reset{
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* scale in */
.side-navigation-wrapper.scale-in-reset,
.side-navigation-wrapper.enter-right.scale-in-reset{
  -webkit-transform: translate3d(0,0,0) scale(0.9);
  transform: translate3d(0,0,0) scale(0.9);
}
.side-navigation-wrapper.scale-in,
.side-navigation-wrapper.enter-right.scale-in{
  -webkit-transform: translate3d(0,0,0) scale(1);
  transform: translate3d(0,0,0) scale(1);
}

/* Animation Properties */
.side-navigation-wrapper,
.reveal-side-navigation{
  -webkit-transition: -webkit-transform 550ms, visibility 0ms;
  transition: transform 550ms, visibility 0ms;
}

/* Side navigation fallback for
 non CSS3 supporting browsers */
.side-navigation-wrapper.hide{
  visibility: hidden;
  left: -35rem;
}
.side-navigation-wrapper.enter-right.hide{
  right: -35rem;
}
.element-show-left{
  left: 35rem;
}
.element-show-right{
  left: -35rem;
}
.element-show-left,
.element-show-right{
  position: relative !important;
}

/* Menu */
.side-navigation{
  width: 100%;
}
.side-navigation:before,
.side-navigation:after{
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
}
.side-navigation ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
.side-navigation ul li{
  width:100%;
  display:block;
  margin-bottom: 0;
}
.side-navigation > ul > li > a{
  padding: 1.3rem 4rem;
}
.side-navigation ul li a{

  text-decoration: none;
  display: block;
  word-wrap: break-word;

  transition-property: background, color;
  transition-duration: 300ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.side-navigation ul li a:hover{
  color: $white;
}
.side-navigation ul li.current > a{
  color:$white;
  font-weight: bold;
}
.side-navigation ul li.current > a:hover{
  color:$white;
}