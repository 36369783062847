/*Clearfix*/
.clear {
  width: 0;
  height: 0;
  display: block;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.clearfix:before,
.clearfix:after,
.row:before,
.row:after{
  height: 0;
  content: ".";
  display: block;
  overflow: hidden;
}
.clearfix:after,
.row:after{
  clear: both;
}
