/* Nav Alignment */
.navigation.nav-left {
  float: left !important;
}

.navigation.nav-left > ul > li:first-child > a {
  padding-left: 0;
  margin-left: 0;
}

.navigation.nav-right {
  float: right !important;
}

.navigation.nav-right > ul > li:last-child > a {
  padding-right: 0;
  margin-right: 0;
}

.navigation.nav-center {
  float: none !important;
  display: block;
}

/* Multiple Nav Blocks Margins & Padding */
.navigation.nav-left {
  margin-right: 2.5rem;
}

.navigation.nav-right {
  margin-left: 2.5rem;
}

.navigation:last-child {
  margin: 0;
}

/* Nav Buttons and Dropdowns */
.navigation .dropdown .button {
  width: auto;
}

.navigation .button [class*="icon-"] {
  margin-right: 0;
}

/* Nav Structure */
.navigation ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.navigation.nav-center > ul {
  text-align: center;
}

.navigation ul li {
  margin: 0;
  float: left;
  position: relative;
}

.navigation.nav-center > ul > li {
  margin-left: -0.4rem;
  float: none;
  display: inline-block !important;
  vertical-align: top;
}

/* Nav Styling */
.navigation > ul > li > a {
  padding: 0 1.5rem;
  background-color: rgba(0, 0, 0, 0);
  line-height: 5rem;
}

.navigation ul li a {
  font-size: 1.1rem;
  color: #878787;
  text-decoration: none;
  display: block;
  word-wrap: break-word;
}

.navigation ul li > a:hover {
  background: none;
  color: $white;
}

.navigation ul li.current > a {
  color: $white;
}

.navigation ul li.current > a:hover {
  color: $white;
}

/* Menu Animation Attributes */
.navigation ul li a {
  -webkit-transition-property: background, border-color, color, line-height;
  transition-property: background, border-color, color, line-height;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

/* Aux Nav Toggle Button*/
.aux-navigation {
  display: none;
}

.navigation-show,
.navigation-hide {
  width: 4.4rem;
  height: 4.4rem;
  text-align: center;
  float: right;
}

.navigation-show a,
.navigation-hide a {
  height: 4.4rem;
  text-align: center;
  display: block;
}

.navigation-show span,
.navigation-hide span {
  width: 100%;
  line-height: 4.4rem;
}

.navigation-hide {
  position: relative;
  z-index: 10;
}

.navigation-hide a,
.navigation-hide a:hover {
  color: $white;
}

/* Mobile */
.mobile .logo.logo-center {
  float: left;
}

.mobile header .navigation:not(.secondary-navigation) {
  display: none !important;
}

/* Mobile Access */
.mobile .aux-navigation {
  display: table !important;
  visibility: visible;
}
