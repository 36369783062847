ul, ol {
  margin: 0 0 3rem 0;
  padding: 0;
  font-size: 1.4rem;
}
ul {
  list-style: none;
}
ol {
  list-style: decimal inside;
}
ul.disc {
  list-style:disc inside;
}
ul.circle {
  list-style:circle inside;
}
ul.square {
  list-style:square inside;
}
ol.decimal-leading-zero {
  list-style:decimal-leading-zero inside;
}
ol.upper-roman {
  list-style:upper-roman inside;
}
ol.lower-alpha {
  list-style:lower-alpha inside;
}
ul ul, ul ol,
ol ol, ol ul {
  margin: 0.3rem 0 0.3rem 2rem;
  list-style: inherit;
}

/* Icon List */
ul [class*="icon-"],
ol [class*="icon-"]{
  margin-right: 1rem;
}

/* List Group */
.list-group.solid,
.list-group.dashed,
.list-group-dotted{
  border: none;
}
.list-group li{
  padding: 1rem 0;
  border-bottom: 1px solid #eeeeee;
}
.list-group li li{
  border-top: 1px solid #eeeeee;
}
.list-group > li:first-child{
  padding-top: 0;
}
.list-group li li:last-child{
  padding-bottom: 0;
}
.list-group li:last-child{
  border-bottom: none;
  border-width: 0;
}
.list-group ul,
.list-group ol{
  margin: 0;
}
.list-group.boxed li{
  padding: 1rem 2rem;
}
.list-group.boxed li li:last-child{
  padding: 1rem 2rem;
  border-top: 1px solid;
}
.list-group li ul{
  padding-top: 1rem;
}
.list-group[class*="border-"] li,
.list-group[class*="border-"] li li,
.list-group[class*="border-"] li ul{
  border-color: inherit !important;
}
.list-group li *:last-child{
  margin-bottom: 0;
}

/* Sizes */
.list-group.small li li{
  padding-left: 1.5rem;
  margin-top: 0;
}
.list-group.small li{
  padding: 0.7rem 0;
}
.list-groups.small ul,
.list-group.small ol{
  margin-top: 0.7rem !important;
}
.list-group.boxed.small li,
.list-groups.boxed.small li{
  padding: 0.7rem 1.5rem;
}
.list-group.medium li{
  padding: 1rem 0;
}
.list-groups.medium ul,
.list-group.medium ol{
  margin-top: 1rem !important;
}
.list-group.boxed.medium li,
.list-group.boxed.medium li{
  padding: 1rem 2rem;
}
.list-group.large li{
  padding: 1.3rem 0;
}
.list-groups.large ul,
.list-group.large ol{
  margin-top: 1.3rem !important;
}
.list-group.boxed.large li,
.list-group.boxed.large li{
  padding: 1.3rem 2.5rem;
}
.list-group.xlarge li{
  padding: 1.6rem 0;
}
.list-groups.xlarge ul,
.list-group.xlarge ol{
  margin-top: 1.6rem !important;
}
.list-group.boxed.xlarge li,
.list-group.boxed.xlarge li{
  padding: 1.6rem 3rem;
}

/* Styles */
.list-group.dotted li{
  border-bottom-style: dotted;
}
.list-group.dotted li li{
  border-top-style: dotted;
}
.list-group.dashed li{
  border-bottom-style: dashed;
}
.list-group.dashed li li{
  border-top-style: dashed;
}
.list-group.thick li{
  border-bottom-width: 0.2rem;
}
.list-group.thick li li{
  border-top-width: 0.2rem;
}
.list-group:not(.boxed) li:last-child{
  border-bottom: none;
  padding-bottom: 0 !important;
}
.list-group li li:last-child{
  border-top: none;
}
.list-group.boxed li{
  border: 1px solid;
  border-bottom: none;
}
.list-group.boxed li:last-child{
  border-bottom: 1px solid;
}
.list-group.boxed.dotted li{
  border-style: dotted;
  border-bottom: none;
}
.list-group.boxed.dotted li:last-child{
  border-bottom: 1px dotted;
}
.list-group.boxed.dotted li li:last-child{
  border-top-style: dotted;
}
.list-group.boxed.dashed li{
  border-style: dashed;
  border-bottom: none;
}
.list-group.boxed.dashed li:last-child{
  border-bottom: 1px dashed;
}
.list-group.boxed.dashed li li:last-child{
  border-top-style: dashed;
}
.list-group.boxed.thick li,
.list-group.boxed.thick li:last-child{
  border-width: 0.2rem;
}
.list-group.rounded li:first-child{
  border-radius: 0.3rem 0.3rem 0 0;
}
.list-group.rounded li:last-child{
  border-radius: 0 0 0.3rem 0.3rem;
}
.list-group[class*="border-"],
.list-group[class*="border-"].dotted,
.list-group[class*="border-"].dashed,
.list-group[class*="border-"].thick,
.list-group[class*="border-"]:hover,
.list-group.boxed[class*="border-"],
.list-group.boxed[class*="border-"].dotted,
.list-group.boxed[class*="border-"].dashed,
.list-group.boxed[class*="border-"].thick,
.list-group.boxed[class*="border-"]:hover {
  border-width: 0;
}
.list-horizontal li{
  display: inline-block;
}