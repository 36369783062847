.menu-box{
  overflow: visible;
  margin-bottom: 3rem;
}
.menu-list,
.menu-list li{
  margin: 0 0 3rem 0;
}
.menu-list.boxed{
  padding: 2rem;
}
.menu-list li{
  position: relative;
}
.menu-list li:last-child{
  margin-bottom: 0;
}
.menu-list h4{
  margin-bottom: 0;
}
.menu-list h4 + p{
  margin-top: 1.3rem;
}
.menu-list p{
  margin-bottom: 0;
}
.menu-list .menu-content{
  padding-right: 10rem;
}
.menu-list .menu-title,
.menu-list .menu-description,
.menu-list .menu-price{
  background-color: $white;
}
.menu-list h4,
.menu-list .menu-title,
.menu-list .menu-content,
.menu-list .menu-description,
.menu-list .menu-price{
  position: relative;
  z-index: 1;
}
.menu-list .menu-price{
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 0;
}
.menu-title .menu-price{
  position: relative;
  right: auto;
  left: auto;
  bottom: auto;
}
.menu-list .menu-line{
  height: 1px;
  margin: 0;
  border: 0;
  display: block;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: linear-gradient(to right, #ccc 100%, rgba(0,0,0,0) 0%);
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
}

/* Callout */
.menu-list .callout{
  padding: 2rem;
  background-color: #f4f4f4;
}
.menu-list .callout *{
  background-color: inherit;
}
.menu-list .special-item{
  font-size: 1.3rem;
  font-style: italic;
  text-transform: none;
}

/* Line bottom position */
.menu-list h4 .menu-line{
  bottom: 4px;
}
.menu-list .menu-content .menu-line{
  bottom: 8px;
}

/* Sizes */
.menu-box.small,
.menu-list.boxed.small{
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.menu-box.small .menu-list,
.menu-list.boxed.small li:last-child{
  margin-bottom: 1.5rem
}
.menu-box.medium,
.menu-list.boxed.medium{
  padding: 2rem 2rem 0 2rem;
}
.menu-box.medium .menu-list,
.menu-list.boxed.medium li:last-child{
  margin-bottom: 2rem
}
.menu-box.large,
.menu-list.boxed.large{
  padding: 2.5rem 2.5rem 0 2.5rem;
}
.menu-box.large .menu-list,
.menu-list.boxed.large li:last-child{
  margin-bottom: 2.5rem
}
.menu-box.xlarge,
.menu-list.boxed.xlarge{
  padding: 3rem 3rem 0 3rem;
}
.menu-box.xlarge .menu-list,
.menu-list.boxed.xlarge li:last-child{
  margin-bottom: 3rem
}

/* Alignment */
.menu-list.center .menu-line{
  display: none;
}
.menu-list.center .menu-price{
  position: relative;
}
.menu-list.center .menu-content{
  padding: 0;
}
.menu-list.center .menu-description{
  display: block;
}
.menu-list.right .menu-content{
  padding-left: 10rem;
  padding-right: 0;
}
.menu-list.right .menu-price{
  left: 0;
  right: auto;
}

/* Styles */
.menu-list.boxed{
  border-style: solid;
  border-width: 1px;
}
.menu-list.boxed.dashed{
  border-style: dashed;
}
.menu-list.boxed.dotted{
  border-style: dotted;
}
.menu-list.rounded,
.menu-list.rounded .callout{
  border-radius: 0.3rem;
}
.dotted .menu-line{
  height: 2px;
  background-image: radial-gradient(circle closest-side, #ccc 99%,rgba(0,0,0,0) 0%);
}
.dashed .menu-line{
  height: 1px;
  background-image:  linear-gradient(to right, #ccc 50%, rgba(0,0,0,0) 0%);
}
.menu-list .callout.rounded{
  border-radius: 0.3rem;
}