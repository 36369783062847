h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

p {
  margin: 0 0 3rem 0;
}

h1[class*="border-"],
h1[class*="bkg-"],
h1 span[class*="border-"],
h1 span[class*="bkg-"],
h2[class*="border-"],
h2[class*="bkg-"],
h2 span[class*="border-"],
h2 span[class*="bkg-"],
h3[class*="border-"],
h3[class*="bkg-"],
h3 span[class*="border-"],
h3 span[class*="bkg-"],
h4[class*="border-"],
h4[class*="bkg-"],
h4 span[class*="border-"],
h4 span[class*="bkg-"],
h5[class*="border-"],
h5[class*="bkg-"],
h5 span[class*="border-"],
h5 span[class*="bkg-"],
h6[class*="border-"],
h6[class*="bkg-"],
h6 span[class*="border-"],
h6 span[class*="bkg-"],
p[class*="border-"],
p[class*="bkg-"] {
  padding: 0.4rem 0.6rem;
}

address {
  margin: 0 0 3rem 0;
}

.separator {
  width: 10rem;
  height: 0.4rem;
  display: block;
  background-color: #333;
  margin-bottom: 2rem;
}

.separator.small {
  width: 7rem;
  height: 0.2rem;
}

.separator.large {
  width: 13rem;
  height: 0.6rem;
}

.separator.xlarge {
  width: 16rem;
  height: 0.8rem;
}

.separator.full-width {
  width: 100%;
}

.center .separator,
.separator.center {
  margin-right: auto;
  margin-left: auto;
}

.right .separator,
.separator.right {
  margin-left: auto;
}

/* Styles */
em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

small {
  font-size: 65%;
}

[class*="weight-"] p,
[class*="weight-"] span,
[class*="weight-"] a {
  font-weight: inherit;
}

.weight-light {
  font-weight: 300;
}

.weight-regular {
  font-weight: 400;
}

.weight-bold {
  font-weight: 500;
}

[class*="lspacing-"] p,
[class*="lspacing-"] span,
[class*="lspacing-"] a {
  letter-spacing: inherit;
}

.lspacing-small {
  letter-spacing: 0.2rem;
}

.lspacing-medium {
  letter-spacing: 0.3rem;
}

.lspacing-large {
  letter-spacing: 0.5rem;
}

.lspacing-xlarge {
  letter-spacing: 0.8rem;
}

/* Links */
a, p a {
  color: $grey-60;
  text-decoration: none;
  outline: none;
}

a:hover,
p a:hover {
  color: #333;
  text-decoration: none;
}

/* Text Highlight */
::selection,
::-moz-selection {
  background: $grey-80;
  color: $white;
}

code, samp, kbd {
  font-family: "Courier New", Courier, monospace, sans-serif;
  text-align: left;
}

pre {
  max-width: 100%;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

pre code {
  line-height: 1.5;
  white-space: pre-wrap;
}

/* SKIN */

body {
  letter-spacing: 0;
  font-weight: 300;
  font-size: 1.7rem;
  line-height: 1.8;
  color: $grey-80;
}

.font-sans-serif,
body,
input,
textarea,
select,
button,
.button,
.header .navigation > ul > li > a,
.side-navigation > ul > li > a,
.overlay-navigation > ul > li > a {
  font-family: $font-stack;
}

.font-brand,
h3,
h4,
h5,
h6,
.post-info,
.project-title,
.product-title,
blockquote cite {
  font-family: $header-font-stack;
}

.font-serif,
h1,
h2,
blockquote p,
.project-description,
.occupation,
.product .product-details .product-price,
.project-attribute .project-value,
.overlay-navigation-wrapper .menu-title {
  font-family: $alternate-font-stack;
}

/* Titles */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1;
  font-weight: 500;
  text-transform: none;
  color: $black;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: $brand;
}

h1, .h1 {
  font-size: 4.5rem;
}

h2, .h2 {
  font-size: 3.3rem;
}

h3, .h3 {
  font-size: 2.3rem;
}

h4, .h4 {
  font-size: 1.8rem;
}

h5, .h5 {
  font-size: 1.6rem;
}

h6, .h6 {
  font-size: 1.2rem;
}

.sidebar .widget-title,
.sidebar .widget-title a {
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $grey-60;
}

.side-navigation-wrapper .widget-title,
.side-navigation-wrapper .widget-title a {
  font-size: 1.3rem;
  color: $white;
}

/* Link Colors */
a,
p a,
.box a:not(.button) {
  color: $brand;
}

.sidebar .widget a {
  color: $grey-60;
}

a:hover,
p a:hover,
.box a:not(.button):hover,
.social-list li a:hover,
.team-1 .social-list a:hover,
.team-2 .social-list a:hover,
.blog-masonry .with-background .post-read-more a:hover,
.post-info a:hover,
.post-info-over a:hover,
.post-info-over a:hover span,
.post-author-aside a:hover,
.post-love a:hover,
.post-love a:hover span,
.scroll-down a:hover,
.widget a:not(.button):hover {
  color: $brand;
}

/* Footer Specific Link Colors */
.footer .widget a:not(.button):hover,
.footer a:hover + .post-info .post-date,
.footer .navigation a:hover,
.footer .social-list a:hover,
.footer .footer-bottom a:hover {
  color: $white;
}

.footer-light .widget a:not(.button):hover,
.footer-light a:hover + .post-info .post-date,
.footer-light .navigation a:hover,
.footer-light .social-list a:hover,
.footer-light .footer-bottom a:hover {
  color: $brand;
}

/* Special Links */
a[class*="icon-"]:hover {
  text-decoration: none;
}

a.animated-link {
  position: relative;
}

blockquote.large {
  font-size: 2rem;
  font-weight: 300;
}

.lead {
  font-size: 2.1rem;
  line-height: 3.2rem;
}

.post-content {
  font-size: 1.9rem;
  line-height: 2.9rem;
}

/* Title Classes */
.title-xlarge {
  font-size: 6.3rem;
  line-height: 1.5;
}

.title-large {
  font-size: 5.1rem;
  line-height: 1.25;
}

.title-medium {
  font-size: 3.2rem;
  line-height: 1.1;
}

.title-small {
  font-size: 1.1rem;
  line-height: 1;
}

/* Text Classes */
.text-xlarge {
  font-size: 4.1rem;
  line-height: 1.4;
}

.text-large {
  font-size: 3.4rem;
  line-height: 1.4;
}

.text-medium {
  font-size: 2.4rem;
  line-height: 1.4;
}

.text-small {
  font-size: 1.6rem;
  line-height: 1.4;
}

/* Transform */
.text-uppercase{
  text-transform: uppercase;
}

/* Special Text Classes */

.status-code-title {
  font-size: 14rem;
  line-height: 1;
}

/* Weight Classes */
.weight-light {
  font-weight: 300;
}

.weight-regular {
  font-weight: 400;
}

.weight-bold {
  font-weight: 500;
}
.font-serif.weight-bold {
  font-weight: 700;
}



@media only screen and (max-width: 768px) {
  h1,
  .title-xlarge {
    font-size: 4.4rem;
  }
  h2,
  .title-large {
    font-size: 3.2rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.3rem;
  }
  h6 {
    font-size: 1.1rem;
  }
  h5.tms-caption,
  .tmp-content h5 {
    font-size: 1.4rem;
  }
  .tms-caption.title-xlarge,
  .tmp-content .title-xlarge {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  /* Lead */
  .lead {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
  .post-content {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  /* Text Classes */
  .text-xlarge {
    font-size: 3.1rem;
  }
  .text-large {
    font-size: 2.5rem;
  }
  .text-medium {
    font-size: 1.9rem;
  }
  .text-small {
    font-size: 1.1rem;
  }

  /* Helpers */
  .fullscreen-section .background-on-mobile {
    background: #f4f4f4;
  }
  .fullscreen-section .background-on-mobile * {
    color: $grey-60 !important;
  }
  .fullscreen-section .background-on-mobile h1,
  .fullscreen-section .background-on-mobile h2,
  .fullscreen-section .background-on-mobile h3,
  .fullscreen-section .background-on-mobile h4,
  .fullscreen-section .background-on-mobile h5,
  .fullscreen-section .background-on-mobile h6 {
    color: $black !important;
  }
}