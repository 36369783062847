input{
  line-height: normal;
}
input,
textarea{
  width: 100%;
  padding: 1.3rem 2.5rem;
  margin: 0 0 1.5rem 0;
}
input[type="submit"]{
  width: auto;
}
input:focus,
textarea:focus,
select:focus{
  outline:none;
}
input:disabled,
textarea:disabled,
select:disabled,
.disabled{
  opacity: 0.6;
  cursor: default;
}
.checkbox:disabled,
.radio:disabled{
  opacity: 0;
  cursor: default;
}
.form-element,
textarea{
  background: #f9f9f9;
  font-size: 1.3rem;
  color: $grey-60;
  line-height: 1.4;
  border: 1px solid #eeeeee;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance:	none;
  appearance: none;
}
.form-element:focus,
textarea:focus{
  background: $white;
  color: #333;
  border: 1px solid #ccc;
  box-shadow: 0 0 0.2rem 0 rgba(0,0,0,0.2);
}
textarea{
  min-width: 10rem;
  min-height: 20rem;
}
.textarea-no-resize{
  resize: none;
}
label{
  margin-bottom: 0.8rem;
  color: $grey-60;
  font-size: 1.2rem;
  display: block;
}
label.pull-left{
  margin-bottom: 0;
  margin-right: 1rem;
  padding: 1.4rem 0;
  line-height: normal;
  float: left;
}
label.pull-right{
  margin-bottom: 0;
  margin-left: 1rem;
  padding: 1.4rem 0;
  line-height: normal;
  float: left;
}
label.emphasized{
  color: $grey-60;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Select */
select{
  width: 100%;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-select{
  width: 100%;
  padding: 0 !important;
  position: relative;
}
.form-select select{
  width: 100%;
  padding: 1.3rem 2.5rem;
}
.form-select:after{
  font-family: 'icomoon';
  font-size: 1.5rem;
  content: "\e63e";
  pointer-events: none;
  position: absolute;
  top: 1.3rem;
  right: 2rem;
}

/* Checkbox & Radio */
input[type="checkbox"]{
  -webkit-appearance: checkbox;
  -moz-appearance:	checkbox;
  appearance: checkbox;
}
.checkbox,
.radio{
  opacity: 0;
  position: absolute;
}
.checkbox,
.checkbox-label,
.radio,
.radio-label{
  margin-bottom: 2rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox:disabled + .checkbox-label,
.checkbox:disabled + .checkbox-label:before,
.radio:disabled + .radio-label,
.radio:disabled + .radio-label:before{
  opacity: 0.6;
}
.checkbox-label ~ .checkbox-label,
.radio-label ~ .radio-label{
  margin-left: 1rem;
}
.checkbox-label,
.radio-label{
  color: $grey-60;
  position: relative;
}
.checkbox + .checkbox-label:before,
.radio + .radio-label:before{
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: middle;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  content: '';
  font-family: 'icomoon';
  text-align: center;
}
.checkbox:checked + .checkbox-label,
.radio:checked + .radio-label{
  opacity: 1;
}
.checkbox:checked + .checkbox-label:before {
  content: '\e63a';
  background: $white;
  color: $grey-60;
}
.radio:checked + .radio-label:before {
  background: $grey-60;
  box-shadow: inset 0px 0px 0px 4px $white;
}
.checkbox:focus + .checkbox-label,
.radio:focus + .radio-label {
  outline: none;
}

/* Style */
.form-element.rounded,
textarea.rounded,
.checkbox.rounded + .checkbox-label:before{
  border-radius: 0.3rem;
}
.radio + .radio-label:before{
  border-radius: 50%;
}
.form-element.pill{
  border-radius: 10.5rem;
}

/* Merged */
@media only screen and (min-width: 768px){
  .merged-form-elements .column:first-child{
    padding-right: 0;
  }
  .merged-form-elements .column:last-child{
    padding-left: 0;
  }
  .merged-form-elements .column + .column{
    padding: 0;
  }
  .merged-form-elements .column .form-element{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .merged-form-elements .column + .column .form-element{
    border-radius: 0;
  }
  .merged-form-elements .column:last-child .button{
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/* Sizes */
.form-element.small{
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
}
.form-element.small select{
  padding: 1rem 3.5rem 1rem 1.5rem;
  font-size: 1.1rem;
}
.form-select.small:after{
  top: 0.8rem;
  right: 1rem;
}
.checkbox.small + .checkbox-label:before,
.radio.small + .radio-label:before{
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
}
.checkbox.small + .checkbox-label,
.radio.small + .radio-label{
  font-size: 1.1rem;
}
.form-element.medium{
  padding: 1.3rem 2.5rem;
}
.form-element.medium select{
  padding: 1.3rem 4.5rem 1.3rem 2.5rem;
}
.form-element.large{
  padding: 1.6rem 3rem;
  font-size: 1.4rem;
}
.form-element.large select{
  padding: 1.6rem 5rem 1.6rem 3rem;
  font-size: 1.4rem;
}
.form-select.large:after{
  top: 1.7rem;
  right: 2.5rem;
}
.checkbox.large + .checkbox-label:before,
.radio.large + .radio-label:before{
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
}
.checkbox.large + .checkbox-label,
.radio.large + .radio-label{
  font-size: 1.4rem;
}
.form-element.xlarge{
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem;
}
.form-element.xlarge select{
  padding: 1.9rem 5.5rem 1.9rem 3.5rem;
  font-size: 1.5rem;
}
.form-select.xlarge:after{
  top: 2rem;
  right: 3rem;
}
.checkbox.xlarge + .checkbox-label:before,
.radio.xlarge + .radio-label:before{
  width: 2.7rem;
  height: 2.7rem;
  line-height: 2.7rem;
}
.checkbox.xlarge + .checkbox-label,
.radio.xlarge + .radio-label{
  font-size: 1.5rem;
}

/* Editable Div */
div[contenteditable="true"]{
  background: #f9f9f9;
  color: $grey-60;
  border: 1px solid #eeeeee;
}
div[contenteditable="true"]:focus{
  background: $white;
  color: #333333;
  border: 1px solid #cccccc;
  box-shadow: 0 0 0.2rem 0 rgba(0,0,0,0.2);
  outline: none;
}

/* Sizes */
div[contenteditable="true"]{
  padding: 1.3rem 2.5rem;
}
div[contenteditable="true"].small{
  padding: 1rem 1.5rem;
  font-size: 1rem;
}
div[contenteditable="true"].medium{
  padding: 1.3rem 2.5rem;
}
div[contenteditable="true"].large{
  padding: 1.6rem 3rem;
  font-size: 1.4rem;
}
div[contenteditable="true"].xlarge{
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem;
}