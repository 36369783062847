.label{
  padding: 0.2rem 0.6rem 0.3rem 0.6rem;
  border: 1px solid #eeeeee;
}
.label [class*="icon-"]:before{
  margin-left: 0;
  margin-right: 0.5rem;
}
.label,
.highlight{
  background: #eeeeee;
  color: $grey-60;
}

/* Styles */
.label.rounded{
  border-radius: 0.3rem;
}