@media only screen and (max-width: 1140px){

  /* Grid */
  .row{
    max-width: 96rem;
  }

  /* Content grid */
  .content-grid-5 .grid-item,
  .content-grid-6 .grid-item{
    width: 25%;
  }

  /* Feature Column */
  .feature-column-group .width-3{
    width: 50%;
  }
}
@media only screen and (max-width: 1023px){

  /* Content grid */
  .content-grid-5 .grid-item,
  .content-grid-6 .grid-item{
    width: 33.33333%;
  }
}
@media only screen and (max-width: 960px) {

  /* Grid */
  .row{
    max-width: 86rem;
  }

  /* Header */
  header .primary-navigation{
    display: none !important;
  }
  .nav-bar .logo,
  .nav-bar .logo.logo-center{
    margin: 0;
    float: left;
  }

  /* Mobile Access */
  .aux-navigation{
    display: table !important;
    visibility: visible;
  }

  /* Feature Column */
  .feature-column-group .feature-column{
    padding: 4rem;
  }

}
@media only screen and (max-width: 768px) {

  /* Grid */
  .row{
    max-width: 60rem;
  }
  .width-1,
  .width-2,
  .width-3,
  .width-4,
  .width-5,
  .width-6,
  .width-7,
  .width-8,
  .width-9,
  .width-10,
  .width-11,
  .width-12 {
    width: 100%;
  }
  [class*="offset-"]{
    margin-left: 0;
  }
  .column[class*='push-'],
  .column[class*='pull-']{
    position: static;
    left: 0;
    right: 0;
  }

  /* Content grid */
  [class*="content-grid-"] .grid-item{
    width: 100%;
  }

  /* Button icon */
  button.no-label-on-mobile span:not([class*="icon-"]),
  .button.no-label-on-mobile span:not([class*="icon-"]){
    display: none;
  }
  button.no-label-on-mobile [class*="icon-"],
  .button.no-label-on-mobile [class*="icon-"]{
    margin: 0;
  }

  /* Blockquote */
  blockquote {
    width: 100% !important;
  }

  /* Feature Column */
  .feature-column-group .width-3{
    width: 100%;
  }
  /* Helper Classes for mobile */
  .hide-on-mobile{
    display: none !important;
    visibility: hidden !important;
  }
  .show-on-mobile{
    display: block !important;
    visibility: visible !important;
  }
  .pull-left-on-mobile{
    float: left !important;
  }
  .pull-right-on-mobile{
    float: right !important;
  }
  .clear-float-on-mobile{
    float: none !important;
  }
  .v-align-bottom,
  .v-align-bottom-on-mobile{
    padding-bottom: 2rem !important;
    vertical-align: bottom !important;
  }
  .v-align-top,
  .v-align-top-on-mobile{
    padding-top: 2rem !important;
    vertical-align: top !important;
  }
  .no-padding-on-mobile{
    padding: 0 !important
  }
  .no-background-on-mobile{
    background: none !important;
    border: none !important;
  }
  .thumbnail .v-align-top,
  .thumbnail .v-align-top-on-mobile,
  .thumbnail .v-align-bottom,
  .thumbnail .v-align-bottom-on-mobile{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .background-on-mobile{
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #f4f4f4;
    color: $grey-60;
    text-align: left;
    position: relative;
    z-index: 1
  }
  .v-align-top-on-mobile .background-on-mobile{
    margin-top: -2rem;
  }
  .v-align-bottom-on-mobile .background-on-mobile{
    margin-bottom: -2rem;
  }
  .left-on-mobile{
    text-align: left !important;
  }
  .center-on-mobile{
    text-align: center !important;
  }
  .right-on-mobile{
    text-align: right !important;
  }
  .row.background-on-mobile{
    padding-left: 0;
    padding-right: 0;
  }
  .background-on-mobile .column > *:last-child{
    margin-bottom: 0;
  }
  .row > .background-on-mobile{
    margin-left: 0;
    margin-right: 0;
  }
  .no-transition-on-mobile{
    -webkit-transition-duration: 0ms !important;
    transition-duration: 0ms !important;
  }
}

@media only screen and (max-width: 480px) {

  /* Grid */
  .row{
    max-width: 35rem;
  }

  /* Content grid */
  [class*="content-grid-"] .grid-item{
    width: 100%;
  }

  /* Side navigation */
  .side-navigation-wrapper,
  .side-navigation-inner{
    width: 30rem;
  }
  .element-reveal-left{
    -webkit-transform: translate3d(30rem,0,0);
    transform: translate3d(30rem,0,0);
  }
  .element-reveal-right{
    -webkit-transform: translate3d(-30rem,0,0);
    transform: translate3d(-30rem,0,0);
  }
  .side-navigation-wrapper.hide{
    right: 30rem;
  }
  .side-navigation-wrapper.enter-right.hide{
    right: -30rem;
  }
  .element-show-left{
    left: 30rem;
  }
  .element-show-right{
    left: -30rem;
  }

  /* Lists */
  .list-group{
    margin-bottom: 0;
  }

  /* Blockquote */
  blockquote {
    width: 100% !important;
  }

  /* Tabs*/
  .tabs.vertical .tab-nav,
  .tabs.vertical.right .tab-nav,
  .tabs.vertical .tab-panes,
  .tabs.vertical.right .tab-panes{
    width: 100%;
    float: none;
  }
  .tab-nav li{
    width: 100%;
    float: none;
  }
  .tab-nav li a{
    width: 100%;
  }
  .tabs.rounded li:first-child a{
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .tabs.rounded .tab-panes{
    border-radius: 0 0 0.3rem 0.3rem;
  }
  .tabs li.active:last-child a{
    border-bottom: 1px solid #eeeeee;
  }
  .tabs.vertical li.active a,
  .tabs.vertical.right li.active a{
    border-left: 1px solid #eeeeee;
  }
  .tabs.vertical .tab-panes,
  .tabs.vertical.right  .tab-panes{
    border-top: none;
  }
}
@media only screen and (max-width : 350px) {

  /* Grid */
  .row{
    max-width: 100%;
  }
}

