.dropdown{
  position: relative;
  margin-bottom: 1rem;
}
.dropdown button,
.dropdown .button{
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}
.dropdown.disabled .button{
  cursor: default;
}
.dropdown.disabled button,
.dropdown.disabled .button,
.dropdown.disabled button:hover,
.dropdown.disabled .button:hover{
  border-color: #ddd !important;
  background: $white !important;
  color: #ddd !important;
}
.dropdown-list{
  width: 16rem;
  margin-top: 0.3rem;
  text-align: left;
  background: $white;
  border: 1px solid #ddd;
  box-shadow: 0 0.2rem 0.2rem rgba(0,0,0,.3);
  border-radius: 0.3rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 101;
  -webkit-transition-property: -webkit-transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
}
.dropdown-list:not(.custom-content) li a{
  padding: 0.6rem 2.5rem;
  display: block;
  font-size: 1.2rem;
  color: $grey-60;
  font-weight: normal;
  cursor: pointer;
}
.dropdown-list li a:hover{
  background: #f4f4f4;
  color: #333;
  text-decoration: none;
}
.dropdown-list li.current a{
  background: #f4f4f4;
  color: $grey-60;
}
.dropdown-list.active{
  opacity: 1;
  visibility: visible;
}
.dropdown-list .list-header{
  padding: 0.6rem 2.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  background: #ddd;
}
.dropdown.small .button,
.dropdown.small button{
  padding: 1rem 1.5rem;
}
.dropdown.small .dropdown-list li a{
  padding: 0.4rem 1.5rem;
}
.dropdown.small .dropdown-list{
  width: 14rem;
}
.dropdown.medium .button,
.dropdown.medium button{
  padding: 1.3rem 2.5rem;
}
.dropdown.medium .dropdown-list li a{
  padding: 0.6rem 2.5rem;
}
.dropdown.medium .dropdown-list{
  width: 16rem;
}
.dropdown.large .button,
.dropdown.large button{
  padding: 1.6rem 3rem;
}
.dropdown.large .dropdown-list li a{
  padding: 0.9rem 3rem;
}
.dropdown.large .dropdown-list{
  width: 18rem;
}
.dropdown.xlarge .button,
.dropdown.xlarge button{
  padding: 1.9rem 3.5rem;
}
.dropdown.xlarge .dropdown-list li a{
  padding: 1.2rem 3.5rem;
}
.dropdown.xlarge .dropdown-list{
  width: 20rem;
}
.dropdown.rounded .button,
.dropdown.rounded button{
  border-radius: 0.3rem;
}