

@font-face {
  font-family: 'icomoon';
  src:url('../fonts/entypo.eot');
  src:url('../fonts/entypo.eot') format('embedded-opentype'),
  url('../fonts/entypo.woff') format('woff'),
  url('../fonts/entypo.ttf') format('truetype'),
  url('../fonts/entypo.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-add-to-list:before {content: "\e600";}
.icon-add-user:before {content: "\e601";}
.icon-address:before {content: "\e602";}
.icon-adjust:before {content: "\e603";}
.icon-air:before {content: "\e604";}
.icon-aircraft-landing:before {content: "\e605";}
.icon-aircraft-take-off:before {content: "\e606";}
.icon-aircraft:before {content: "\e607";}
.icon-align-bottom:before {content: "\e608";}
.icon-align-horizontal-middle:before {content: "\e609";}
.icon-align-left:before {content: "\e60a";}
.icon-align-right:before {content: "\e60b";}
.icon-align-top:before {content: "\e60c";}
.icon-align-vertical-middle:before {content: "\e60d";}
.icon-archive:before {content: "\e60e";}
.icon-area-graph:before {content: "\e60f";}
.icon-arrow-bold-down:before {content: "\e610";}
.icon-arrow-bold-left:before {content: "\e611";}
.icon-arrow-bold-right:before {content: "\e612";}
.icon-arrow-bold-up:before {content: "\e613";}
.icon-arrow-down:before {content: "\e614";}
.icon-arrow-left:before {content: "\e615";}
.icon-arrow-long-down:before {content: "\e616";}
.icon-arrow-long-left:before {content: "\e617";}
.icon-arrow-long-right:before {content: "\e618";}
.icon-arrow-long-up:before {content: "\e619";}
.icon-arrow-right:before {content: "\e61a";}
.icon-arrow-up:before {content: "\e61b";}
.icon-arrow-with-circle-down:before {content: "\e61c";}
.icon-arrow-with-circle-left:before {content: "\e61d";}
.icon-arrow-with-circle-right:before {content: "\e61e";}
.icon-arrow-with-circle-up:before {content: "\e61f";}
.icon-attachment:before {content: "\e620";}
.icon-awareness-ribbon:before {content: "\e621";}
.icon-back-in-time:before {content: "\e622";}
.icon-back:before {content: "\e623";}
.icon-bar-graph:before {content: "\e624";}
.icon-battery:before {content: "\e625";}
.icon-beamed-note:before {content: "\e626";}
.icon-bell:before {content: "\e627";}
.icon-blackboard:before {content: "\e628";}
.icon-block:before {content: "\e629";}
.icon-book:before {content: "\e62a";}
.icon-bookmark:before {content: "\e62b";}
.icon-bookmarks:before {content: "\e62c";}
.icon-bowl:before {content: "\e62d";}
.icon-box:before {content: "\e62e";}
.icon-briefcase:before {content: "\e62f";}
.icon-browser:before {content: "\e630";}
.icon-brush:before {content: "\e631";}
.icon-bucket:before {content: "\e632";}
.icon-bug:before {content: "\e633";}
.icon-cake:before {content: "\e634";}
.icon-calculator:before {content: "\e635";}
.icon-calendar:before {content: "\e636";}
.icon-camera:before {content: "\e637";}
.icon-ccw:before {content: "\e638";}
.icon-chat:before {content: "\e639";}
.icon-check:before {content: "\e63a";}
.icon-down:before {content: "\e63b";}
.icon-left:before {content: "\e63c";}
.icon-right:before {content: "\e63d";}
.icon-down-open-mini:before {content: "\e63e";}
.icon-left-open-mini:before {content: "\e63f";}
.icon-right-open-mini:before {content: "\e640";}
.icon-up-open-mini:before {content: "\e641";}
.icon-down-open-big:before {content: "\e642";}
.icon-left-open-big:before {content: "\e643";}
.icon-right-open-big:before {content: "\e644";}
.icon-up-open-big:before {content: "\e645";}
.icon-up:before {content: "\e646";}
.icon-down-circled:before {content: "\e647";}
.icon-left-circled:before {content: "\e648";}
.icon-right-circled:before {content: "\e649";}
.icon-up-circled:before {content: "\e64a";}
.icon-circle-with-cross:before {content: "\e64b";}
.icon-circle-with-minus:before {content: "\e64c";}
.icon-circle-with-plus:before {content: "\e64d";}
.icon-circle:before {content: "\e64e";}
.icon-circular-graph:before {content: "\e64f";}
.icon-clapperboard:before {content: "\e650";}
.icon-classic-computer:before {content: "\e651";}
.icon-clipboard:before {content: "\e652";}
.icon-clock:before {content: "\e653";}
.icon-cloud:before {content: "\e654";}
.icon-code:before {content: "\e655";}
.icon-cog:before {content: "\e656";}
.icon-colours:before {content: "\e657";}
.icon-compass:before {content: "\e658";}
.icon-fast-backward:before {content: "\e659";}
.icon-fast-forward:before {content: "\e65a";}
.icon-jump-to-start:before {content: "\e65b";}
.icon-next:before {content: "\e65c";}
.icon-paus:before {content: "\e65d";}
.icon-play:before {content: "\e65e";}
.icon-record:before {content: "\e65f";}
.icon-stop:before {content: "\e660";}
.icon-volume:before {content: "\e661";}
.icon-copy:before {content: "\e662";}
.icon-creative-commons-attribution:before {content: "\e663";}
.icon-creative-commons-noderivs:before {content: "\e664";}
.icon-creative-commons-noncommercial-eu:before {content: "\e665";}
.icon-creative-commons-noncommercial-us:before {content: "\e666";}
.icon-creative-commons-public-domain:before {content: "\e667";}
.icon-creative-commons-remix:before {content: "\e668";}
.icon-creative-commons-share:before {content: "\e669";}
.icon-creative-commons-sharealike:before {content: "\e66a";}
.icon-creative-commons:before {content: "\e66b";}
.icon-credit-card:before {content: "\e66c";}
.icon-credit:before {content: "\e66d";}
.icon-crop:before {content: "\e66e";}
.icon-cancel:before {content: "\e66f";}
.icon-cup:before {content: "\e670";}
.icon-cw:before {content: "\e671";}
.icon-cycle:before {content: "\e672";}
.icon-database:before {content: "\e673";}
.icon-dial-pad:before {content: "\e674";}
.icon-direction:before {content: "\e675";}
.icon-document-landscape:before {content: "\e676";}
.icon-document:before {content: "\e677";}
.icon-documents:before {content: "\e678";}
.icon-dot-single:before {content: "\e679";}
.icon-dots-three-horizontal:before {content: "\e67a";}
.icon-dots-three-vertical:before {content: "\e67b";}
.icon-dots-two-horizontal:before {content: "\e67c";}
.icon-dots-two-vertical:before {content: "\e67d";}
.icon-download:before {content: "\e67e";}
.icon-drink:before {content: "\e67f";}
.icon-drive:before {content: "\e680";}
.icon-drop:before {content: "\e681";}
.icon-edit:before {content: "\e682";}
.icon-email:before {content: "\e683";}
.icon-emoji-flirt:before {content: "\e684";}
.icon-emoji-happy:before {content: "\e685";}
.icon-emoji-neutral:before {content: "\e686";}
.icon-emoji-sad:before {content: "\e687";}
.icon-erase:before {content: "\e688";}
.icon-eraser:before {content: "\e689";}
.icon-export:before {content: "\e68a";}
.icon-eye-with-line:before {content: "\e68b";}
.icon-eye:before {content: "\e68c";}
.icon-feather:before {content: "\e68d";}
.icon-fingerprint:before {content: "\e68e";}
.icon-flag:before {content: "\e68f";}
.icon-flash:before {content: "\e690";}
.icon-flashlight:before {content: "\e691";}
.icon-flat-brush:before {content: "\e692";}
.icon-flow-branch:before {content: "\e693";}
.icon-flow-cascade:before {content: "\e694";}
.icon-flow-line:before {content: "\e695";}
.icon-flow-parallel:before {content: "\e696";}
.icon-flow-tree:before {content: "\e697";}
.icon-flower:before {content: "\e698";}
.icon-folder-images:before {content: "\e699";}
.icon-folder-music:before {content: "\e69a";}
.icon-folder-video:before {content: "\e69b";}
.icon-folder:before {content: "\e69c";}
.icon-forward:before {content: "\e69d";}
.icon-funnel:before {content: "\e69e";}
.icon-game-controller:before {content: "\e69f";}
.icon-gauge:before {content: "\e6a0";}
.icon-globe:before {content: "\e6a1";}
.icon-graduation-cap:before {content: "\e6a2";}
.icon-grid:before {content: "\e6a3";}
.icon-hair-cross:before {content: "\e6a4";}
.icon-hand:before {content: "\e6a5";}
.icon-heart-outlined:before {content: "\e6a6";}
.icon-heart:before {content: "\e6a7";}
.icon-help-with-circle:before {content: "\e6a8";}
.icon-help:before {content: "\e6a9";}
.icon-home:before {content: "\e6aa";}
.icon-hour-glass:before {content: "\e6ab";}
.icon-image-inverted:before {content: "\e6ac";}
.icon-image:before {content: "\e6ad";}
.icon-images:before {content: "\e6ae";}
.icon-inbox:before {content: "\e6af";}
.icon-infinity:before {content: "\e6b0";}
.icon-info-with-circle:before {content: "\e6b1";}
.icon-info:before {content: "\e6b2";}
.icon-install:before {content: "\e6b3";}
.icon-key:before {content: "\e6b4";}
.icon-keyboard:before {content: "\e6b5";}
.icon-lab-flask:before {content: "\e6b6";}
.icon-landline:before {content: "\e6b7";}
.icon-language:before {content: "\e6b8";}
.icon-laptop:before {content: "\e6b9";}
.icon-layers:before {content: "\e6ba";}
.icon-leaf:before {content: "\e6bb";}
.icon-level-down:before {content: "\e6bc";}
.icon-level-up:before {content: "\e6bd";}
.icon-lifebuoy:before {content: "\e6be";}
.icon-light-bulb:before {content: "\e6bf";}
.icon-light-down:before {content: "\e6c0";}
.icon-light-up:before {content: "\e6c1";}
.icon-line-graph:before {content: "\e6c2";}
.icon-link:before {content: "\e6c3";}
.icon-list:before {content: "\e6c4";}
.icon-location-pin:before {content: "\e6c5";}
.icon-location:before {content: "\e6c6";}
.icon-lock-open:before {content: "\e6c7";}
.icon-lock:before {content: "\e6c8";}
.icon-log-out:before {content: "\e6c9";}
.icon-login:before {content: "\e6ca";}
.icon-loop:before {content: "\e6cb";}
.icon-magnet:before {content: "\e6cc";}
.icon-magnifying-glass:before {content: "\e6cd";}
.icon-mail:before {content: "\e6ce";}
.icon-man:before {content: "\e6cf";}
.icon-map:before {content: "\e6d0";}
.icon-mask:before {content: "\e6d1";}
.icon-medal:before {content: "\e6d2";}
.icon-megaphone:before {content: "\e6d3";}
.icon-menu:before {content: "\e6d4";}
.icon-merge:before {content: "\e6d5";}
.icon-message:before {content: "\e6d6";}
.icon-mic:before {content: "\e6d7";}
.icon-minus:before {content: "\e6d8";}
.icon-mobile:before {content: "\e6d9";}
.icon-modern-mic:before {content: "\e6da";}
.icon-moon:before {content: "\e6db";}
.icon-mouse-pointer:before {content: "\e6dc";}
.icon-mouse:before {content: "\e6dd";}
.icon-music:before {content: "\e6de";}
.icon-network:before {content: "\e6df";}
.icon-new-message:before {content: "\e6e0";}
.icon-new:before {content: "\e6e1";}
.icon-news:before {content: "\e6e2";}
.icon-newsletter:before {content: "\e6e3";}
.icon-note:before {content: "\e6e4";}
.icon-notification:before {content: "\e6e5";}
.icon-notifications-off:before {content: "\e6e6";}
.icon-old-mobile:before {content: "\e6e7";}
.icon-old-phone:before {content: "\e6e8";}
.icon-open-book:before {content: "\e6e9";}
.icon-palette:before {content: "\e6ea";}
.icon-paper-plane:before {content: "\e6eb";}
.icon-pencil:before {content: "\e6ec";}
.icon-phone:before {content: "\e6ed";}
.icon-pie-chart:before {content: "\e6ee";}
.icon-pin:before {content: "\e6ef";}
.icon-plus:before {content: "\e6f0";}
.icon-popup:before {content: "\e6f1";}
.icon-power-plug:before {content: "\e6f2";}
.icon-price-ribbon:before {content: "\e6f3";}
.icon-price-tag:before {content: "\e6f4";}
.icon-print:before {content: "\e6f5";}
.icon-progress-empty:before {content: "\e6f6";}
.icon-progress-full:before {content: "\e6f7";}
.icon-progress-one:before {content: "\e6f8";}
.icon-progress-two:before {content: "\e6f9";}
.icon-publish:before {content: "\e6fa";}
.icon-quote:before {content: "\e6fb";}
.icon-radio:before {content: "\e6fc";}
.icon-remove-user:before {content: "\e6fd";}
.icon-reply-all:before {content: "\e6fe";}
.icon-reply:before {content: "\e6ff";}
.icon-resize-100:before {content: "\e700";}
.icon-resize-full-screen:before {content: "\e701";}
.icon-retweet:before {content: "\e702";}
.icon-rocket:before {content: "\e703";}
.icon-round-brush:before {content: "\e704";}
.icon-rss:before {content: "\e705";}
.icon-ruler:before {content: "\e706";}
.icon-save:before {content: "\e707";}
.icon-scissors:before {content: "\e708";}
.icon-select-arrows:before {content: "\e709";}
.icon-share-alternative:before {content: "\e70a";}
.icon-share:before {content: "\e70b";}
.icon-shareable:before {content: "\e70c";}
.icon-shield:before {content: "\e70d";}
.icon-shop:before {content: "\e70e";}
.icon-shopping-bag:before {content: "\e70f";}
.icon-shopping-basket:before {content: "\e710";}
.icon-shopping-cart:before {content: "\e711";}
.icon-shuffle:before {content: "\e712";}
.icon-signal:before {content: "\e713";}
.icon-sound-mix:before {content: "\e714";}
.icon-sound-mute:before {content: "\e715";}
.icon-sound:before {content: "\e716";}
.icon-sports-club:before {content: "\e717";}
.icon-spreadsheet:before {content: "\e718";}
.icon-squared-cross:before {content: "\e719";}
.icon-squared-minus:before {content: "\e71a";}
.icon-squared-plus:before {content: "\e71b";}
.icon-star-outlined:before {content: "\e71c";}
.icon-star:before {content: "\e71d";}
.icon-stopwatch:before {content: "\e71e";}
.icon-suitcase:before {content: "\e71f";}
.icon-swap:before {content: "\e720";}
.icon-sweden:before {content: "\e721";}
.icon-switch:before {content: "\e722";}
.icon-tablet-mobile-combo:before {content: "\e723";}
.icon-tablet:before {content: "\e724";}
.icon-tag:before {content: "\e725";}
.icon-text-document-inverted:before {content: "\e726";}
.icon-text-document:before {content: "\e727";}
.icon-text:before {content: "\e728";}
.icon-thermometer:before {content: "\e729";}
.icon-thumbs-down:before {content: "\e72a";}
.icon-thumbs-up:before {content: "\e72b";}
.icon-thunder-cloud:before {content: "\e72c";}
.icon-ticket:before {content: "\e72d";}
.icon-time-slot:before {content: "\e72e";}
.icon-tools:before {content: "\e72f";}
.icon-traffic-cone:before {content: "\e730";}
.icon-trash:before {content: "\e731";}
.icon-tree:before {content: "\e732";}
.icon-triangle-down:before {content: "\e733";}
.icon-triangle-left:before {content: "\e734";}
.icon-triangle-right:before {content: "\e735";}
.icon-triangle-up:before {content: "\e736";}
.icon-trophy:before {content: "\e737";}
.icon-tv:before {content: "\e738";}
.icon-typing:before {content: "\e739";}
.icon-uninstall:before {content: "\e73a";}
.icon-unread:before {content: "\e73b";}
.icon-untag:before {content: "\e73c";}
.icon-upload-to-cloud:before {content: "\e73d";}
.icon-upload:before {content: "\e73e";}
.icon-user:before {content: "\e73f";}
.icon-users:before {content: "\e740";}
.icon-v-card:before {content: "\e741";}
.icon-video-camera:before {content: "\e742";}
.icon-video:before {content: "\e743";}
.icon-vinyl:before {content: "\e744";}
.icon-voicemail:before {content: "\e745";}
.icon-wallet:before {content: "\e746";}
.icon-warning:before {content: "\e747";}
.icon-water:before {content: "\e748";}
.icon-px-with-circle:before {content: "\e749";}
.icon-px:before {content: "\e74a";}
.icon-app-store:before {content: "\e74b";}
.icon-baidu:before {content: "\e74c";}
.icon-basecamp:before {content: "\e74d";}
.icon-behance:before {content: "\e74e";}
.icon-creative-cloud:before {content: "\e74f";}
.icon-dribbble-with-circle:before {content: "\e750";}
.icon-dribbble:before {content: "\e751";}
.icon-dropbox:before {content: "\e752";}
.icon-evernote:before {content: "\e753";}
.icon-facebook-with-circle:before {content: "\e754";}
.icon-facebook:before {content: "\e755";}
.icon-flattr:before {content: "\e756";}
.icon-flickr-with-circle:before {content: "\e757";}
.icon-flickr:before {content: "\e758";}
.icon-foursquare:before {content: "\e759";}
.icon-github-with-circle:before {content: "\e75a";}
.icon-github:before {content: "\e75b";}
.icon-google-drive:before {content: "\e75c";}
.icon-google-hangouts:before {content: "\e75d";}
.icon-google-play:before {content: "\e75e";}
.icon-google-with-circle:before {content: "\e75f";}
.icon-google:before {content: "\e760";}
.icon-grooveshark:before {content: "\e761";}
.icon-houzz:before {content: "\e762";}
.icon-icloud:before {content: "\e763";}
.icon-instagram-with-circle:before {content: "\e764";}
.icon-instagram:before {content: "\e765";}
.icon-lastfm-with-circle:before {content: "\e766";}
.icon-lastfm:before {content: "\e767";}
.icon-linkedin-with-circle:before {content: "\e768";}
.icon-linkedin:before {content: "\e769";}
.icon-mail-with-circle:before {content: "\e76a";}
.icon-medium-with-circle:before {content: "\e76b";}
.icon-medium:before {content: "\e76c";}
.icon-mixi:before {content: "\e76d";}
.icon-onedrive:before {content: "\e76e";}
.icon-paypal:before {content: "\e76f";}
.icon-picasa:before {content: "\e770";}
.icon-pinterest-with-circle:before {content: "\e771";}
.icon-pinterest:before {content: "\e772";}
.icon-qq-with-circle:before {content: "\e773";}
.icon-qq:before {content: "\e774";}
.icon-raft-with-circle:before {content: "\e775";}
.icon-raft:before {content: "\e776";}
.icon-rainbow:before {content: "\e777";}
.icon-rdio-with-circle:before {content: "\e778";}
.icon-rdio:before {content: "\e779";}
.icon-renren:before {content: "\e77a";}
.icon-scribd:before {content: "\e77b";}
.icon-sina-weibo:before {content: "\e77c";}
.icon-skype-with-circle:before {content: "\e77d";}
.icon-skype:before {content: "\e77e";}
.icon-slideshare:before {content: "\e77f";}
.icon-smashing:before {content: "\e780";}
.icon-soundcloud:before {content: "\e781";}
.icon-spotify-with-circle:before {content: "\e782";}
.icon-spotify:before {content: "\e783";}
.icon-stumbleupon-with-circle:before {content: "\e784";}
.icon-stumbleupon:before {content: "\e785";}
.icon-swarm:before {content: "\e786";}
.icon-tripadvisor:before {content: "\e787";}
.icon-tumblr-with-circle:before {content: "\e788";}
.icon-tumblr:before {content: "\e789";}
.icon-twitter-with-circle:before {content: "\e78a";}
.icon-twitter:before {content: "\e78b";}
.icon-vimeo-with-circle:before {content: "\e78c";}
.icon-vimeo:before {content: "\e78d";}
.icon-vine-with-circle:before {content: "\e78e";}
.icon-vine:before {content: "\e78f";}
.icon-vk-alternitive:before {content: "\e790";}
.icon-vk-with-circle:before {content: "\e791";}
.icon-vk:before {content: "\e792";}
.icon-windows-store:before {content: "\e793";}
.icon-xing-with-circle:before {content: "\e794";}
.icon-xing:before {content: "\e795";}
.icon-yelp:before {content: "\e796";}
.icon-youko-with-circle:before {content: "\e797";}
.icon-youko:before {content: "\e798";}
.icon-youtube-with-circle:before {content: "\e799";}
.icon-youtube:before {content: "\e79a";}

/* Sizes */
[class*="icon-"].small{
  font-size: 1.6rem;
}
[class*="icon-"].medium{
  font-size: 2.4rem;
}
[class*="icon-"].large{
  font-size: 3.2rem;
}
[class*="icon-"].xlarge{
  font-size: 4.8rem;
}
.icon-boxed,
.icon-circled{
  width: 6.6rem;
  height: 6.6rem;
  margin-bottom: 0.4rem;
  line-height: 6.6rem;
  text-align: center;
  border: 1px solid #eeeeee;
}
.icon-boxed.small,
.icon-circled.small{
  width: 5.8rem;
  height: 5.8rem;
  line-height: 5.6rem;
}
.icon-boxed.medium,
.icon-circled.medium{
  width: 6.6rem;
  height: 6.6rem;
  line-height: 6.4rem;
}
.icon-boxed.large,
.icon-circled.large{
  width: 7.4rem;
  height: 7.4rem;
  line-height: 7.2rem;
}
.icon-boxed.xlarge,
.icon-circled.xlarge{
  width: 9rem;
  height: 9rem;
  line-height: 8.8rem;
}

/* Styles */
.icon-boxed.rounded{
  border-radius: 0.3rem;
}
.icon-circled{
  border-radius: 10rem;
}