input,
textarea,
button,
a{
  transition-property: background, border-color, color, opacity;
  transition-duration: 300ms;
}
.bar,
.box,
.overlay-info,
.thumbnail img{
  transition-property: padding, background, color, opacity, box-shadow, transform;
  transition-duration: 300ms;
}
.no-transition,
.no-transition-reset{
  transition-duration: 0ms !important;
}