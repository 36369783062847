.button-group{
  margin-bottom: 3rem;
}
button + button,
.button + .button{
  margin-left: 1rem;
}
button,
.button{
  padding: 1.3rem 2.5rem;
  margin: 0 0 1rem 0;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 1.4;
  color: $grey-60;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  font-weight: normal;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance:	none;
  appearance:	none;
}
button,
.button {
  line-height: 1.4;
}
button:focus,
.button:focus{
  outline: none;
}
button:hover,
.button:hover{
  text-decoration: none;
  color: $grey-60;
  background-color: #d0d0d0;
  border-color: #d0d0d0;
}
/* Sizes */
button.small,
.button.small{
  padding: 1rem 1.5rem;
  font-size: 1.1rem !important;
}
button.medium,
.button.medium{
  padding: 1.3rem 2.5rem;
  font-size: 1.3rem !important;
}
button.large,
.button.large{
  padding: 1.6rem 3rem;
  font-size: 1.6rem !important;
  line-height: 2.1rem;
}
button.xlarge,
.button.xlarge{
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem !important;
}

/* Icon Button */
button [class*="icon-"].left,
.button [class*="icon-"].left{
  margin-right: 0.5rem;
}
button [class*="icon-"].right,
.button [class*="icon-"].right{
  margin-left: 0.5rem;
}

/* Button Content */
.button-content{
  display: inline-block;
}
.button-content *{
  display: block;
}

/* Firefox Fix */
input[type="submit"]::-moz-focus-inner{
  border: 0 !important;
}

/* Styles */
button.rounded,
.button.rounded{
  border-radius: 0.3rem;
}
button.pill,
.button.pill{
  border-radius: 10.5rem;
}
button.shadow,
.button.shadow{
  -webkit-box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
}
button.hard-shadow,
.button.hard-shadow{
  border-bottom: 3px solid rgba(0,0,0,.15);
}
button.disabled,
.button.disabled{
  cursor: default;
  color: #cccccc;
  background-color: #f9f9f9;
  border-color: #eeeeee;
}
button.disabled:hover,
.button.disabled:hover{
  background-color: #f9f9f9;
  border-color: #eeeeee;
}

/* Button Nav */
.button-nav{
  padding: 0;
  margin: 0;
  list-style: none;
}
.button-nav li{
  float: left;
  margin: 0 1px 0 0;
}
.button-nav li .button.rounded,
.button-nav li button.rounded,
.button-nav li .button.pill,
.button-nav li button.pill{
  border-radius: 0;
}
.button-nav li:first-child .button.rounded,
.button-nav li:first-child button.rounded,
.button-nav.rounded li:first-child .button,
.button-nav.rounded li:first-child button{
  border-radius: 0.3rem 0 0 0.3rem;
}
.button-nav li:last-child .button.rounded
.button-nav li:last-child button.rounded,
.button-nav.rounded li:last-child .button,
.button-nav.rounded li:last-child button{
  border-radius: 0 0.3rem 0.3rem 0;
}
.button-nav li:first-child .button.pill,
.button-nav li:first-child button.pill,
.button-nav.pill li:first-child .button,
.button-nav.pill li:first-child button{
  border-radius: 10.5rem 0 0 10.5rem;
}
.button-nav li:last-child .button.pill,
.button-nav li:last-child button.pill,
.button-nav.pill li:last-child .button,
.button-nav.pill li:last-child button{
  border-radius: 0 10.5rem 10.5rem 0;
}
.button-nav.center li{
  margin-left: -0.3rem;
  display: inline-block;
  float: none;
}
