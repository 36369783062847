html{
  font-size: 62.5%;
}
html, body {
  max-width: 100%;
}
body {
  padding: 0;
  margin: 0;
  background: $white;
  color: $grey-60;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1.8;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.page-fade-reset{
  opacity: 1;
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}
.page-fade-out{
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-backface-visibility: hidden;
}

.no-js,
.no-js .horizon{
  opacity: 1;
  visibility: visible;
}
body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
.content{
  background-color: $white;
}
.content{
  position: relative;
  z-index: 1;
}
.section-block{
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.replicable-content{
  padding-bottom: 4rem;
}
.section-block.featured-media {
  padding-top: 0;
  padding-bottom: 0;
}
@media only screen and (min-width: 768px){
  .section-block.section-overlap{
    padding-top: 0;
    margin-top: -7rem;
    background-color: transparent !important;
    z-index: 2;
  }
}
@media only screen and (max-width: 960px){
  .content-inner:not([class*="offset-"]),
  .sidebar{
    width: 100%;
  }
  .content-inner.column[class*='push-'],
  .content-inner.column[class*='pull-'],
  .sidebar.column[class*='push-'],
  .sidebar.column[class*='pull-'],
  .blog [class*="pagination-"] .column[class*='push-'],
  .blog [class*="pagination-"] .column[class*='pull-']{
    position: static;
    left: 0;
    right: 0;
  }
}
