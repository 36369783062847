.left{
  text-align: left;
}
.right{
  text-align: right;
}
.center{
  text-align: center;
}
@media screen and (max-width: 960px) {
  .mobile-left{
    text-align: left;
  }
  .mobile-right{
    text-align: right;
  }
  .mobile-center{
    text-align: center;
  }
}
.pull-left{
  float: left !important;
}
.pull-right{
  float: right !important;
}
.v-align-bottom{
  vertical-align: bottom !important;
}
.v-align-top{
  vertical-align: top !important;
}
.v-align-top .background-on-mobile{
  margin-top: -2rem;
}
.v-align-bottom .background-on-mobile{
  margin-bottom: -2rem;
}
.hide{
  display: none !important;
  visibility: hidden !important;
}
.show{
  display: block !important;
  visibility: visible !important;
}
.mobile .hide-on-mobile,
.mobile .hide-on-mobile *{
  display: none !important;
  visibility: visible !important;
}
.inline,
[class*="border-"].inline,
[class*="bkg-"].inline{
  width: auto !important;
  display: inline-block !important;
}
.no-margins{
  margin: 0 !important;
}
.no-margin-top{
  margin-top: 0 !important;
}
.no-margin-right{
  margin-right: 0 !important;
}
.no-margin-bottom{
  margin-bottom: 0 !important;
}
.no-margin-left{
  margin-left: 0 !important;
}
.no-padding{
  padding: 0 !important;
}
.no-padding-top{
  padding-top: 0 !important;
}
.no-padding-right{
  padding-right: 0 !important;
}
.no-padding-bottom{
  padding-bottom: 0 !important;
}
.no-padding-left{
  padding-left: 0 !important;
}
.overflow-visible{
  overflow: visible !important;
}
.no-scroll{
  overflow: hidden !important;
}
.clear-height{
  height: auto !important;
}
