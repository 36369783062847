.box{
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 3rem;
  border: 1px solid #eeeeee;
  overflow: hidden;
}
.grid-item .box,
.equalize .column > .box{
  height: 100%;
}
.box.dotted{
  border-style: dotted;
}
.box.dashed{
  border-style: dashed;
}
.box > *:first-child{
  margin-top: 0;
}
.box *:not(.form-element):last-child{
  margin-bottom: 0;
}
.box > a:not(.button){
  color: inherit;
  font-weight: bold;
}
.box > a:not(.button):hover{
  color: inherit;
  text-decoration: underline;
}

/* Dismissable */
.box.dismissable{
  max-height: 100rem;
  padding-right: 3rem;
}
.box.dismissable .close{
  text-decoration: none;
  opacity: 0.5;
  display: block;
  float: right;
  position: relative;
  right: -2rem;
}
.box.dismissable .close:hover{
  text-decoration: none;
  opacity: 1;
}

/* Sizes */
.box.small{
  padding: 1rem;
  font-size: 1rem;
}
.box.small.dismissable{
  padding-right: 2rem
}
.box.small.dismissable .close{
  right: -1.5rem;
}
.box.medium{
  padding: 1.5rem;
}
.box.large{
  padding: 2.5rem;
  font-size: 1.4rem;
}
.box.large.dismissable{
  padding-right: 5rem
}
.box.large.dismissable .close{
  right: -3rem;
}
.box.xlarge{
  padding: 3rem;
  font-size: 1.5rem;
}
.box.xlarge.dismissable{
  padding-right: 6rem
}
.box.xlarge.dismissable .close{
  right: -3.5rem;
}

/* Styles */
.box.rounded{
  border-radius: 0.3rem;
}